html {
    height: 100%;
}
body {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    height: 100%;
    line-height: 1.7;
    vertical-align: baseline;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    color: #646464;
    background-color: #fff;
}

@media only screen and (max-width: 1199px) {
  h1 {
    font-size: 34px;
  }
}
@media only screen and (max-width: 991px) {
  h1 {
    font-size: 32px;
  }
}
@media only screen and (max-width: 767px) {
  h1 {
    font-size: 30px;
  }
}
h2 {
  font-size: 28px;
}
@media only screen and (max-width: 1199px) {
  h2 {
    font-size: 26px;
  }
}
@media only screen and (max-width: 991px) {
  h2 {
    font-size: 24px;
  }
}
@media only screen and (max-width: 767px) {
  h2 {
    font-size: 22px;
  }
}
h3 {
  font-size: 22px;
}
@media only screen and (max-width: 991px) {
  h3 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 767px) {
  h3 {
    font-size: 18px;
  }
}
h4 {
  font-size: 20px;
}
@media only screen and (max-width: 991px) {
  h4 {
    font-size: 18px;
  }
}
@media only screen and (max-width: 767px) {
  h4 {
    font-size: 16px;
  }
}
h5 {
  font-size: 18px;
}
@media only screen and (max-width: 991px) {
  h5 {
    font-size: 16px;
  }
}

.fxt-content-between {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@media only screen and (max-width: 767px) {
  .fxt-none-767 {
    display: none !important;
  }
}
@media only screen and (max-width: 991px) {
  .fxt-none-991 {
    display: none !important;
  }
}

.fxt-template-animation {
  position: relative;
  z-index: 1;
  width: 100%;
  opacity: 0;
  overflow: hidden;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-animation .fxt-transformY-50 {
  opacity: 0;
  -webkit-transform: translateY(50px);
  -ms-transform: translateY(50px);
  transform: translateY(50px);
}
.loaded.fxt-template-animation {
  opacity: 1;
}
.loaded.fxt-template-animation .fxt-transformY-50 {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  -webkit-transition: all 1s ease-in-out;
  -o-transition: all 1s ease-in-out;
  transition: all 1s ease-in-out;
}
.loaded.fxt-template-animation .fxt-transition-delay-1 {
  -webkit-transition-delay: 0.1s;
  -o-transition-delay: 0.1s;
  transition-delay: 0.1s;
}
.loaded.fxt-template-animation .fxt-transition-delay-2 {
  -webkit-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}
.loaded.fxt-template-animation .fxt-transition-delay-3 {
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
}
.loaded.fxt-template-animation .fxt-transition-delay-4 {
  -webkit-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
  transition-delay: 0.4s;
}
.loaded.fxt-template-animation .fxt-transition-delay-5 {
  -webkit-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
}
.loaded.fxt-template-animation .fxt-transition-delay-6 {
  -webkit-transition-delay: 0.6s;
  -o-transition-delay: 0.6s;
  transition-delay: 0.6s;
}
.loaded.fxt-template-animation .fxt-transition-delay-7 {
  -webkit-transition-delay: 0.7s;
  -o-transition-delay: 0.7s;
  transition-delay: 0.7s;
}
.loaded.fxt-template-animation .fxt-transition-delay-8 {
  -webkit-transition-delay: 0.8s;
  -o-transition-delay: 0.8s;
  transition-delay: 0.8s;
}
.loaded.fxt-template-animation .fxt-transition-delay-9 {
  -webkit-transition-delay: 0.9s;
  -o-transition-delay: 0.9s;
  transition-delay: 0.9s;
}
.loaded.fxt-template-animation .fxt-transition-delay-10 {
  -webkit-transition-delay: 1s;
  -o-transition-delay: 1s;
  transition-delay: 1s;
}
.loaded.fxt-template-animation .fxt-transition-delay-11 {
  -webkit-transition-delay: 1.1s;
  -o-transition-delay: 1.1s;
  transition-delay: 1.1s;
}
.loaded.fxt-template-animation .fxt-transition-delay-12 {
  -webkit-transition-delay: 1.2s;
  -o-transition-delay: 1.2s;
  transition-delay: 1.2s;
}
.loaded.fxt-template-animation .fxt-transition-delay-13 {
  -webkit-transition-delay: 1.3s;
  -o-transition-delay: 1.3s;
  transition-delay: 1.3s;
}
.loaded.fxt-template-animation .fxt-transition-delay-14 {
  -webkit-transition-delay: 1.4s;
  -o-transition-delay: 1.4s;
  transition-delay: 1.4s;
}
.loaded.fxt-template-animation .fxt-transition-delay-15 {
  -webkit-transition-delay: 1.5s;
  -o-transition-delay: 1.5s;
  transition-delay: 1.5s;
}
.loaded.fxt-template-animation .fxt-transition-delay-16 {
  -webkit-transition-delay: 1.6s;
  -o-transition-delay: 1.6s;
  transition-delay: 1.6s;
}
.loaded.fxt-template-animation .fxt-transition-delay-17 {
  -webkit-transition-delay: 1.7s;
  -o-transition-delay: 1.7s;
  transition-delay: 1.7s;
}
.loaded.fxt-template-animation .fxt-transition-delay-18 {
  -webkit-transition-delay: 1.8s;
  -o-transition-delay: 1.8s;
  transition-delay: 1.8s;
}
.loaded.fxt-template-animation .fxt-transition-delay-19 {
  -webkit-transition-delay: 1.9s;
  -o-transition-delay: 1.9s;
  transition-delay: 1.9s;
}
.loaded.fxt-template-animation .fxt-transition-delay-20 {
  -webkit-transition-delay: 2s;
  -o-transition-delay: 2s;
  transition-delay: 2s;
}

.fxt-template-login {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;
  position: relative;
  min-height: 100vh;
  z-index: 1;
  padding: 50px 0 20px;
}
.fxt-template-login .fxt-video-background {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
}
.fxt-template-login .fxt-video-background:before {
  content: "";
  position: absolute;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.fxt-template-login .fxt-video-background .fxt-video {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 1;
}
.fxt-template-login .fxt-checkbox-area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 30px;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.fxt-template-login .fxt-content {
  padding-top: 40px;
  padding-bottom: 15px;
  padding-left: 70px;
  margin-left: 40px;
  border-left: 2px solid rgba(241, 241, 241, 0.15);
}
@media only screen and (max-width: 991px) {
  .fxt-template-login .fxt-content {
    padding-left: 0;
    margin-left: 0;
    margin-top: 40px;
    border-left: 0;
    padding-bottom: 0;
    border-top: 2px solid rgba(241, 241, 241, 0.15);
  }
}
.fxt-template-login .fxt-content h2 {
  font-size: 20px;
  color: #fff;
}
@media only screen and (max-width: 991px) {
  .fxt-template-login .fxt-content h2 {
    text-align: center;
  }
}
.fxt-template-login .fxt-header {
  text-align: center;
}
.fxt-template-login .fxt-logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 40vw;
}
.fxt-template-login .fxt-form .fxt-title {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 30px;
  color: #ffffff;
}
@media only screen and (max-width: 991px) {
  .fxt-template-login .fxt-form .fxt-title {
    font-size: 24px;
    text-align: center;
  }
}
@media only screen and (max-width: 767px) {
  .fxt-template-login .fxt-form .fxt-title {
    font-size: 22px;
  }
}
@media only screen and (max-width: 575px) {
  .fxt-template-login .fxt-form .fxt-title {
    font-size: 20px;
  }
}
.fxt-template-login .fxt-form .fxt-otp-logo {
  margin-bottom: 20px;
  display: block;
}
@media only screen and (max-width: 991px) {
  .fxt-template-login .fxt-form .fxt-otp-logo {
    text-align: center;
  }
}
.fxt-template-login .fxt-form .fxt-otp-label {
  letter-spacing: 1px;
  width: 100%;
  margin-bottom: 15px;
  font-size: 17px;
  font-weight: 500;
  color: #ffffff;
}
@media only screen and (max-width: 991px) {
  .fxt-template-login .fxt-form .fxt-otp-label {
    text-align: center;
  }
}
.fxt-template-login .fxt-form .fxt-sending-to {
  font-size: 16px;
  margin-bottom: 20px;
  color: #dfdfdf;
}
@media only screen and (max-width: 991px) {
  .fxt-template-login .fxt-form .fxt-sending-to {
    text-align: center;
  }
}

.fxt-template-login label, .fxt-template-login p {
    color: #fff;
}
.fxt-template-login .fxt-form .fxt-sending-to span {
  display: block;
}
.fxt-template-login .fxt-form .form-group {
  position: relative;
  z-index: 1;
}
.fxt-template-login .fxt-form .form-group .field-icon {
  position: absolute;
  z-index: 1;
  right: 19px;
  bottom: 18px;
  font-size: 14px;
  color: #bebebe;
}
.fxt-template-login .fxt-form .form-group .field-icon:before {
  padding: 17px 10px;
}
.fxt-template-login .fxt-form .form-control {
  min-height: 50px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid rgba(241, 241, 241, 0.25);
  padding: 10px 15px;
  background-color: rgba(255,255,255,0.1);
  color: #fff;
}
.fxt-template-login .fxt-form input::-webkit-input-placeholder {
  color: #bebebe;
  font-size: 18px;
  font-weight: 300;
}
.fxt-template-login .fxt-form input::-moz-placeholder {
  color: #bebebe;
  font-size: 18px;
  font-weight: 300;
}
.fxt-template-login .fxt-form input:-moz-placeholder {
  color: #bebebe;
  font-size: 18px;
  font-weight: 300;
}
.fxt-template-login .fxt-form input:-ms-input-placeholder {
  color: #bebebe;
  font-size: 18px;
  font-weight: 300;
}
.fxt-template-login .fxt-form .fxt-form-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 25px;
}
.fxt-template-login .fxt-form .fxt-form-row .fxt-form-col {
  padding: 10px 5px;
  text-align: center;
  margin-right: 10px;
  flex-basis: 0;
  flex-grow: 1;
}
.fxt-template-login .fxt-form .fxt-form-row .fxt-form-col:last-child {
  margin-right: 0;
}
.fxt-template-login .fxt-form .fxt-form-btn {
  margin-bottom: 10px;
}
.fxt-template-login .fxt-btn-fill {
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  display: inline-block;
  font-size: 17px;
  font-weight: 500;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border: 0;
  color: #fff;
  border-radius: 3px;
  background-color: #2853a1;
  padding: 10px 36px;
  margin-bottom: 10px;
  width: 100%;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-login .fxt-btn-fill:hover {
  background-color: #26547c;
  border-color: #26547c;
}
.fxt-template-login .fxt-btn-fill:focus {
  outline: none;
}
.fxt-template-login .switcher-text {
  color: #d4d4d4;
  text-decoration: underline;
  font-size: 15px;
  margin-bottom: 5px;
  display: block;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-login .switcher-text:hover {
  color: #ffffff;
}
.fxt-template-login .switcher-text2 {
  color: #d4d4d4;
  text-decoration: underline;
  font-size: 15px;
  display: inline-block;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.fxt-template-login .switcher-text2.inline-text {
  margin-left: 3px;
}
.fxt-template-login .switcher-text2:hover {
  color: #ffffff;
}
.fxt-template-login .checkbox {
  padding-left: 5px;
  margin-right: 30px;
  margin-bottom: 5px;
}
.fxt-template-login .checkbox label {
  padding-left: 20px;
  color: #b9b9b9;
  margin-bottom: 0;
  font-size: 15px;
  position: relative;
}
.fxt-template-login .checkbox label:before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 4px;
  left: 0;
  margin-left: -5px;
  border: 1px solid;
  border-color: #dcdcdc;
  border-radius: 2px;
  background-color: transparent;
  -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  -o-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
  transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.fxt-template-login .checkbox label:after {
  position: absolute;
  margin-left: -20px;
  padding-left: 3px;
  font-size: 10px;
  color: #555555;
}
.fxt-template-login .checkbox input[type="checkbox"] {
  display: none;
}
.fxt-template-login .checkbox input[type="checkbox"]:checked + label::after {
  font-family: 'Font Awesome 5 Free';
  content: "\f00c";
  font-weight: 900;
  color: #ffffff;
  left: 15px;
  top: 4px;
}
.fxt-template-login .checkbox input[type="checkbox"]:checked + label::before {
  background-color: #ffbd3f;
  border-color: #ffbd3f;
}
.fxt-template-login .fxt-footer {
  text-align: center;
}
.fxt-template-login .fxt-footer p {
  color: #b6b6b6;
}
.fxt-template-login .fxt-footer .fxt-resend-wrap {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  font-size: 18px;
}
.fxt-template-login .fxt-footer .fxt-btn-resend {
  margin-left: 3px;
  box-shadow: none;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  color: #d3d3d3;
  text-decoration: underline;
  transition: all 0.3s ease-in-out;
}
.fxt-template-login .fxt-footer .fxt-btn-resend:focus {
  outline: none;
}
.fxt-template-login .fxt-footer .fxt-btn-resend:hover {
  color: #ffffff;
}
.fxt-template-login .fxt-footer .text-or {
  margin-left: 3px;
}
